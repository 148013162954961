import * as React from "react";
import { Button, CssBaseline, TextField, Alert, Collapse, Divider, Link, Grid, Box, Typography, Container } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Logo from "../../../Images/Logo.jsx";
import SideStyles from "../Styles/SignIn.js";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../context/UserProvider.js";
import { ToggleTheme } from "../../../context/ToggleThemeProvider.js";
import { useTheme } from "../../../context/UseThemeProvider.js";
import { ThemeProvider } from "@mui/material/styles";
import { getLogin } from "../../../Api/AuthenticationApi.js";
import Copyright from "../../../Component/Copyright.tsx";
import { useTranslation } from "react-i18next";

export default function SignInPage() {
  const { t } = useTranslation();
  const { setUser } = useUser();
  const navigate = useNavigate();
  var { currentTheme } = useTheme();
  var { ThemeToggle } = ToggleTheme();

  const [loginAttemptFailed, setLoginAttemptFailed] = React.useState(false);
  const [checkEmptyFields, setCheckEmptyFields] = React.useState(false);
  const [showError, setShowError] = React.useState(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const userID = formData.get("userID");
    const password = formData.get("password");

    if (userID && password) {
      setCheckEmptyFields(false);
      try {
        const response = await getLogin({
          userID: userID,
          password: password,
        });
        if (response.status === 200) {
          setLoginAttemptFailed(false);
          setCheckEmptyFields(false);
          const auth = response?.data;
          localStorage.setItem("jwt", auth["token"]);
          setUser(auth["user"]);
          localStorage.setItem("CurrentComponent", "0");
          navigate("/");
        } else {
          throw new Error(`Response status: ${response}`);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          setShowError(error.response.data.message);
        } else {
          setShowError(error?.message);
        }
        setLoginAttemptFailed(true);
      }
    } else {
      setCheckEmptyFields(true);
    }
  };

  React.useEffect(() => {
    localStorage.setItem("ThemeColor", ThemeToggle);
  }, [ThemeToggle]);

  return (
    <Box className="block md:flex">
      <Box sx={{
        pl: { md: '20px' },
        width: { md: '60%' },
        pt: { xl: '69px' }
      }}>
        <ThemeProvider theme={currentTheme}>
          <CssBaseline />
          <Container component="main" maxWidth="lg">
            <Box
              sx={{
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Logo />

                <Divider
                  orientation="vertical"
                  variant="middle"
                  aria-hidden="true"
                  flexItem
                  sx={{ m: 2 }}
                />
                <Typography variant="h5">
                  <Box
                    sx={{ fontWeight: 500 }}
                    style={{
                      color: ThemeToggle === "dark" ? currentTheme.palette.narrowWhite.main : "black",
                    }}
                  >
                    Mitarbeiter Online
                  </Box>
                </Typography>
              </Box>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="userID"
                  label={t("username-email")}
                  name="userID"
                  autoComplete="off"
                  variant={ThemeToggle === "dark" ? "filled" : "outlined"}
                  InputLabelProps={{
                    style: { color: "dullBlack" },
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  variant={ThemeToggle === "dark" ? "filled" : "outlined"}
                  InputLabelProps={{
                    style: { color: "dullBlack" },
                  }}
                />
                <Collapse in={loginAttemptFailed}>
                  <Alert severity="error" variant="filled">
                    {showError}
                  </Alert>
                </Collapse>
                <Collapse in={checkEmptyFields}>
                  <Alert severity="warning" variant="filled">
                    {t("require-usernamePassword")}
                  </Alert>
                </Collapse>

                <Button
                  color={"primary"}
                  endIcon={<SendIcon />}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{ height: "50px" }}
                >
                  {t("register")}
                </Button>
                <Grid className="pl-[15%]" item xs>
                  <Link
                    href="resetPassword"
                    variant="body2"
                    color="text.secondary"
                  >
                    {t("forget-usernamePassword")}
                  </Link>
                </Grid>
              </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4, ml: -4 }} />
          </Container>
        </ThemeProvider>
      </Box>


      <Box className={SideStyles.mainDiv}>
        <Box
          className={
            SideStyles.nestedDiv1 +
            (ThemeToggle === "dark" ? "brightness-[0.3]" : "brightness-[0.5]")
          }
        />
        <Box className={SideStyles.nestedDiv2}>
          <Typography
            variant="h3" component="h3"
            sx={{ mt: "50%", color: ((ThemeToggle === "dark" ? currentTheme.palette.narrowWhite.main : "white")) }} >
            {t("welcome")}
          </Typography>
          <Typography
            variant="h6" component="h5"
            sx={{ pt: "10%", ml: "2px", color: (ThemeToggle === "dark" ? currentTheme.palette.narrowWhite.main : "white") }}
            className={
              SideStyles.gladPara
            }
          >
            {t("nice-see")}
          </Typography>
        </Box>
      </Box >
    </Box >

  );
}