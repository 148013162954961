import * as React from "react";
import MenuItems from '../../../utils/MenuItems';
import LogoutIcon from "@mui/icons-material/Logout";
import { useUser } from "../../../context/UserProvider";
import SettingsIcon from '@mui/icons-material/Settings';
import { SidebarHeader } from "../../../Images/DashboardImages";
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function SidebarDrawerItems({ onItemClick, ListIcons, ListMenu }) {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          mt: 1,
          mb: 1,
          ml: 0,
        }}
      >
        <SidebarHeader />
      </Box>
      <Divider sx={{ bgcolor: "secondary.light" }} />
      <List>
        {ListMenu.map(
          //Sidebar menu option based on condition
          (text, index) =>
            ((user.role === "admin" && (MenuItems === MenuItems.HAUPTMENU ||
              text === MenuItems.PERSONEN || text === MenuItems.AUSWERTEN || text === MenuItems.DETAIL1 || text === MenuItems.DETAIL2)) ||
              (user.role === "user" && (text === MenuItems.DETAIL1 || text === MenuItems.DETAIL2)))
            && (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    onItemClick(t(text));
                  }}>
                  <ListItemIcon>{ListIcons[index]}</ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "19px" }}
                    sx={{ ml: -1 }}
                    primary={t(text)}
                  />
                </ListItemButton>
              </ListItem>
            )
        )}
        <Divider sx={{ bgcolor: "secondary.light" }} />
        <ListItem key={"Setting"} disablePadding>
          <ListItemButton
            onClick={() => {
              onItemClick(t("setting"));
            }}>
            <ListItemIcon><SettingsIcon sx={{ color: "white" }} /></ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "19px" }}
              sx={{ ml: -1 }}
              primary={t("setting")}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={"Einstellungen"}
          style={{ position: "fixed", bottom: "0px", width: "270px" }}
          disablePadding
        >
          <ListItemButton
            onClick={() => {
              onItemClick(t("logout"));
            }}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: "common.dark" }} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "19px" }}
              sx={{ ml: -1 }}
              primary={t("logout")}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
}
