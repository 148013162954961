import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';

const TokenizedSearchBar = ({ searchValue, setSearchValue, searchBtn, setSearchBtn, setFiltername, setOriginalData }) => {
    const [selectedTokens, setSelectedTokens] = useState([]);
    const [index, setIndex] = useState(0);
    const { t } = useTranslation();


    const handleTokenChange = (event, newTokens) => {

        setSelectedTokens(newTokens);
        setSearchValue(newTokens);
        setIndex(newTokens.length);
        if (newTokens) {
            setSearchBtn(!searchBtn);
            setFiltername(false);
        } else {
            setOriginalData(true);
        }
    };

    const handleInputChange = (event, value) => {
        event.preventDefault();
        if (value !== "") {
            setSearchValue(prevValues => {
                const newValues = [...prevValues];
                newValues[index] = value;
                return newValues;
            });
            setSearchBtn(!searchBtn);
            setFiltername(false);
        } else if (searchValue === 0 || searchValue?.length === 0) {
            setOriginalData(true);
        }
        else {
            setSearchValue(prevValues => {
                const newValues = [...prevValues];
                newValues[index] = value;
                return newValues;
            });
            setSearchBtn(!searchBtn);
            setFiltername(false);
        }
    }

    return (
        <Autocomplete
            multiple
            id="tokenized-search-bar"
            options={[]}
            freeSolo
            value={selectedTokens}
            onChange={handleTokenChange}
            onInputChange={handleInputChange}
            className="w-[330px]"
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={t("search-nameEmail")} placeholder={t("add-filters")} />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={option}
                        {...getTagProps({ index })}
                    />
                ))
            }
        />
    );
};

export default TokenizedSearchBar;
