import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import MenuItems from '../utils/MenuItems';
import { useTranslation } from 'react-i18next';

export default function BreadCrumb(props) {
    const { currentComponent, setCurrentComponent, userVertragName } = props
    const { t } = useTranslation()

    var setHeaderTitle = (
        <>
            {parseInt(currentComponent + "") === 0 && t(MenuItems.PERSONEN)}
            {parseInt(currentComponent + "") === 1 && t(MenuItems.VERTRAG)}
            {parseInt(currentComponent + "") === 3 && t(MenuItems.DETAIL1)}
            {parseInt(currentComponent + "") === 4 && t(MenuItems.DETAIL2)}
            {parseInt(currentComponent + "") === 5 && t(MenuItems.SETTING)}
        </>
    );

    const breadcrumbs = [
        <Link underline="none" key="1" color="white" className='cursor-pointer' >
            {setHeaderTitle}
        </Link>
    ];

    const vertragCrumbs = [
        <Link underline="none" key="1" color="white" className='cursor-pointer' onClick={() => { setCurrentComponent(0) }}>
            Personen
        </Link>,
        <Link underline="none" key="1" color="white" className='cursor-pointer' onClick={() => { setCurrentComponent(0) }}>
            {userVertragName}
        </Link>
    ];

    React.useEffect(() => {
        // if contract page is open but peron is selected, Navigate to Person page
        if (parseInt(currentComponent + "") === 1 && !userVertragName) {
            setCurrentComponent(0)
        }
    }, [])


    return (
        <Stack spacing={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb" fontSize="large">
                {parseInt(currentComponent + "") === 1 && vertragCrumbs}
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}
