const MenuItems = Object.freeze({
    USER: "user",
    Zeiten: "times",
    DELETE: "delete",
    Gruppen: "group",
    SETTING: "setting",
    Times: "times",
    PERSONEN: "person",
    Bearbeiten: "edit",
    DETAIL1: "detail1",
    DETAIL2: "detail2",
    VERTRAG: "Contract",
    AUSLOGGEN: "logout",
    AUSWERTEN: "evaluate",
    HAUPTMENU: "main-menu",
});

export default MenuItems;