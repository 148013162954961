import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import ErrorBoundary from "../utils/ErrorBoundary";
import { RightArrow, LeftArrow } from "../Images/CommonLogo";

export default function MonthSelectorComponent({
  currentMonth,
  setCurrentMonth,
  currentYear,
  setCurrentYear,
  open,
  setOpen,
  months,
  currentDate,
  setCurrentDate,
  minDate,
  setMinDate,
}) {
  const [today, setToday] = useState(new Date());
  const [syncSelector, setSyncSelector] = useState(new Date());

  useEffect(() => {
    setSyncSelector(new Date(`${currentYear}-${currentMonth + 1}-01 00:01`));
  }, [currentMonth, currentYear]);

  function checkMaxDateValidation(today) {
    if (
      (currentMonth < today?.getMonth() &&
        currentYear === today?.getFullYear()) ||
      currentYear < today?.getFullYear()
    )
      return true;
    return false;
  }

  function checkMinDateValidation() {
    if (
      minDate?.getFullYear() < currentYear ||
      (minDate?.getFullYear() === currentYear &&
        minDate?.getMonth() < currentMonth)
    )
      return true;
    return false;
  }

  return (
    <>
      <ErrorBoundary>
        <span className="mt-2 pb-[10px] left-0 md:pb-[3px] flex mr-6">
          <span
            className={
              "cursor-pointer pt-1 " +
              (!checkMinDateValidation() ? "invisible" : "")
            }
            onClick={() => {
              if (checkMinDateValidation()) {
                if (currentMonth === 0) {
                  setCurrentMonth(11);
                  setCurrentYear(currentYear - 1);
                } else {
                  setCurrentMonth(currentMonth - 1);
                }
              }
            }}
          >
            <LeftArrow />
          </span>
          <span className="">
            <span
              className="font-bold mx-4 my-1 text-[22px] text-white cursor-pointer"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {months[currentMonth]} {currentYear}
            </span>
            {open && (
              <span
                className="absolute left-0 md:left-auto"
                style={{ zIndex: 1000000 }}
              >
                <DatePicker
                  selected={syncSelector}
                  onChange={(date) => {
                    setSyncSelector(date);
                    setCurrentDate(date);
                    setCurrentMonth(date.getMonth());
                    setCurrentYear(date.getFullYear());
                    setOpen(!open);
                  }}
                  showMonthYearPicker
                  maxDate={new Date()}
                  minDate={minDate}
                  inline
                  dateFormat="mm-yyyy"
                />
              </span>
            )}
          </span>

          <span
            className={
              `cursor-pointer pt-1 ` +
              (!checkMaxDateValidation(today) ? "invisible" : "")
            }
            onClick={() => {
              if (checkMaxDateValidation(today)) {
                if (currentMonth === 11) {
                  setCurrentMonth(0);
                  setCurrentYear(currentYear + 1);
                } else {
                  setCurrentMonth(currentMonth + 1);
                }
              }
            }}
          >
            <RightArrow />
          </span>
        </span>
      </ErrorBoundary>
    </>
  );
}
