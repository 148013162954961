import * as React from "react";
import { Modal, Typography, Box, Button, TextField, Alert, Collapse, Select, MenuItem } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { editStyle } from "../Style/PersonModelStyle.js";
import { ToggleTheme } from "../../../context/ToggleThemeProvider.js";
import CloseIcon from "@mui/icons-material/Close";
import { AddPerson, CheckPersonUsername, CheckPersonEmail, CheckPersonEmployeeNumber } from "../../../Api/PersonApi.js";
import { useTranslation } from "react-i18next";

function AddPersonModel({ open, setOpen, handleClose, getAllUser, employeeEditableData, setEmployeeEditableData }) {
  var { ThemeToggle } = ToggleTheme();
  const [role, setRole] = React.useState('user');
  const [showError, setShowError] = React.useState(null);
  const [checkEmptyFields, setCheckEmptyFields] = React.useState(false);
  const [checkUsernameField, setCheckUsernameField] = React.useState(false);
  const [checkEmailField, setCheckEmailField] = React.useState(false);
  const [checkENumberField, setCheckENumberField] = React.useState(false);
  const [addPersonAttemptFailed, setAddPersonAttemptFailed] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [employeenumber, setEmployeenumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const { t } = useTranslation();

  const fetchUserInfo = async (input, type) => {
    if (input && type === "username") {
      if ((!employeeEditableData) || (employeeEditableData && employeeEditableData?.username !== input)) {
        try {
          let response = await CheckPersonUsername(input);
          if (response.employee === 1) {
            setCheckUsernameField(true);
          }
          else {
            setCheckUsernameField(false);
          }
        } catch (error) {
          console.error('Error fetching data:', type);
        }
      }
      else {
        setCheckUsernameField(false);
      }
    }
    else if (input && type === "email") {
      if ((!employeeEditableData) || (employeeEditableData && employeeEditableData?.email !== input)) {
        try {
          let response = await CheckPersonEmail(input);
          if (response.employee === 1) {
            setCheckEmailField(true);
          }
          else {
            setCheckEmailField(false);
          }
        } catch (error) {
          console.error('Error fetching data:', type);
        }
      }
      else {
        setCheckEmailField(false);
      }
    }
    else if (input && type === "eNumber") {
      if ((!employeeEditableData) || (employeeEditableData && employeeEditableData?.employeeNummer !== input)) {
        try {
          let response = await CheckPersonEmployeeNumber(input);
          if (response.employee === 1) {
            setCheckENumberField(true);
          }
          else {
            setCheckENumberField(false);
          }
        } catch (error) {
          console.error('Error fetching data:', type);
        }
      }
      else {
        setCheckENumberField(false);
      }
    }
  }

  React.useEffect(() => {
    if (employeeEditableData) {
      setFirstName(employeeEditableData?.vorname)
      setLastName(employeeEditableData?.nachname)
      setEmail(employeeEditableData?.email)
      setUsername(employeeEditableData?.username)
      setEmployeenumber(employeeEditableData?.employeeNummer)
      setRole(employeeEditableData?.role)
    }
    else {
      resetData();
    }
    setCheckEmailField(false);
    setCheckENumberField(false);
    setCheckUsernameField(false);
  }, [employeeEditableData])


  function resetData() {
    setEmail("")
    setRole("user")
    setLastName("")
    setUsername("")
    setFirstName("")
    setEmployeenumber("")
  }

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleUsernameBlur = (event) => {
    fetchUserInfo(username, "username");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailBlur = (event) => {
    fetchUserInfo(email, "email");

  };

  const handleEmployeeChange = (event) => {
    setEmployeenumber(event.target.value);
  };

  const handleEmployeeBlur = (event) => {
    fetchUserInfo(employeenumber, "eNumber");

  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (username !== "" && email !== "" && firstName !== "" && lastName !== "" && !checkUsernameField && !checkEmailField && !checkENumberField) {
      setCheckEmptyFields(false);
      try {
        const response = await AddPerson({
          "username": username,
          "email": email,
          "firstname": firstName,
          "lastname": lastName,
          "employeenumber": employeenumber,
          "role": role
        });
        if (response.status === 200) {
          resetData();
          getAllUser();
          setOpen(false);
          setCheckEmptyFields(false);
          setAddPersonAttemptFailed(false);
        } else {
          throw new Error(`Response status: ${response}`);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          setShowError(error.response.data.message);
        } else {
          setShowError(error?.message);
        }
        setAddPersonAttemptFailed(true);
      }
    } else {
      setCheckEmptyFields(true);
      setAddPersonAttemptFailed(false);
    }

  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={editStyle}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">
            <Box
              sx={{ fontWeight: 600, w: 100 }}
              style={{
                color: ThemeToggle === "dark" ? "narrowWhite" : "dark",
              }}
            >
              {employeeEditableData?.id ? t('edit-user') : t('add-user')}
            </Box>
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              resetData();
              setOpen(false);
              setCheckEmptyFields(false);
              setEmployeeEditableData("")
              setAddPersonAttemptFailed(false)
            }}
          />
        </Box>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <Box sx={{ display: "flex" }}>
            <TextField
              margin="normal"
              fullWidth
              id="First Name"
              label={t("first-name")}
              value={firstName}
              required
              type="text"
              name="firstName"
              autoComplete="new-password"
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
              onChange={handleFirstName}
            />
            <TextField
              margin="normal"
              fullWidth
              id="Last Name"
              label={t("last-name")}
              value={lastName}
              required
              name="lastName"
              autoComplete="new-password"
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
              onChange={handleLastName}
              sx={{ ml: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t("user-name")}
              name="username"
              value={username}
              autoComplete="new-password"
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              // InputProps={{
              //   readOnly: employeeEditableData?.id ? true : false,
              // }}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
              onBlur={handleUsernameBlur}
              onChange={handleUsernameChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              value={email}
              label={t("email")}
              autoComplete="new-password"
              name="email"
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              // InputProps={{
              //   readOnly: employeeEditableData?.id ? true : false,
              // }}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
              sx={{ ml: 2 }}
            />
          </Box>

          <Box sx={{ display: "flex" }}>
            <TextField
              margin="normal"
              fullWidth
              name="employeeNumber"
              label={t("employee-number")}
              value={employeenumber}
              type="Employee Number"
              id="Employee Number"
              onChange={handleEmployeeChange}
              onBlur={handleEmployeeBlur}
              variant={ThemeToggle === "dark" ? "filled" : "outlined"}
              // InputProps={{
              //   readOnly: employeeEditableData?.id ? true : false,
              // }}
              InputLabelProps={{
                style: { color: "dullBlack" },
              }}
            />
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={role}
              label="Role"
              sx={{ ml: 2, mt: 2, height: 60 }}
              onChange={handleRoleChange}
            >
              <MenuItem value={'user'}>User</MenuItem>
              <MenuItem value={'admin'}>Admin</MenuItem>
            </Select>
          </Box>
          <Collapse in={addPersonAttemptFailed}>
            <Alert severity="error" variant="filled">
              {showError}
            </Alert>
          </Collapse>
          <Collapse in={checkEmptyFields}>
            <Alert severity="warning" variant="filled">
              {t("required-allFields")}
            </Alert>
          </Collapse>
          <Collapse in={checkEmailField}>
            <Alert severity="warning" variant="filled">
              {t("email-taken")}
            </Alert>
          </Collapse>
          <Collapse in={checkUsernameField}>
            <Alert severity="warning" variant="filled">
              {t("username-taken")}
            </Alert>
          </Collapse>
          <Collapse in={checkENumberField}>
            <Alert severity="warning" variant="filled">
              {t("employeeNumber-taken")}
            </Alert>
          </Collapse>

          <Box sx={{ display: "flex" }}>
            <Button
              endIcon={<CloseIcon />}
              fullWidth
              color={"transparent"}
              sx={{ mt: 3, mb: 2, border: 1 }}
              variant="contained"
              style={{
                height: "50px",
                color: ThemeToggle === "dark" ? "white" : "blue",
              }}
              onClick={() => {
                resetData();
                setOpen(false);
                setCheckEmptyFields(false);
                setEmployeeEditableData("");
                setAddPersonAttemptFailed(false)
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              color={"blue"}
              sx={{ mt: 3, mb: 2, ml: 2 }}
              endIcon={<SendIcon />}
              type="submit"
              fullWidth
              variant="contained"
              style={{ height: "50px", color: "white" }}
            >
              {t("submit")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddPersonModel;
