import { jsonToCsv } from '../utils/jsonToCsv';

const CsvDownloader = (jsonData, filename = "data.csv") => {
    const csv = jsonToCsv(jsonData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(a);

};

export default CsvDownloader;