import React, { useEffect, useState } from "react";
import { Box, InputLabel, TextField, Typography, Select, Radio, RadioGroup, FormControlLabel, Divider, MenuItem } from "@mui/material";
import { ToggleTheme } from "../../../context/ToggleThemeProvider";
import { Tick } from "../../../Images/CommonLogo";
import ContractDaysAndTime from "./ContractDaysAndTime";
import { useTheme } from "../../../context/UseThemeProvider";
import CustomDatePicker from "../../../Component/CustomDatePicker";
import { extractTimeFromTimestamp } from '../../../utils/ExtractTime';
import { useTranslation } from "react-i18next";
import { FetchContract, PostContract } from "../../../Api/Contract";
import Confirmation from "../../UserDetail/Component/UserModal/Confirmation"
import dayjs from "dayjs";


export default function ContractComponent() {
  const { t } = useTranslation();
  const [team, setTeam] = useState("");
  var { currentTheme: theme } = useTheme();
  const [contractType, setContractType] = useState("befristet");
  const [contractNumber, setContractNumber] = useState();
  const [validateData, setValidateData] = useState(true);
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())
  const [disableFields, setDisableFields] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);


  const [startTime, setStartTime] = useState({
    monday: new Date().setHours(8, 0),
    tuesday: new Date().setHours(8, 0),
    wednesday: new Date().setHours(8, 0),
    thursday: new Date().setHours(8, 0),
    friday: new Date().setHours(8, 0),
    saturday: new Date().setHours(8, 0),
    sunday: new Date().setHours(8, 0),
  });

  const [endTime, setEndTime] = useState({
    monday: new Date().setHours(17, 0),
    tuesday: new Date().setHours(17, 0),
    wednesday: new Date().setHours(17, 0),
    thursday: new Date().setHours(17, 0),
    friday: new Date().setHours(17, 0),
    saturday: new Date().setHours(17, 0),
    sunday: new Date().setHours(17, 0),
  });

  const [editable, setEditable] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: true,
    sunday: true,
  });

  var employeeID = localStorage.getItem("awo-employeeId")
  var params = `/api/employees/${employeeID}/contracts`

  useEffect(() => {
    var employeeData = FetchContract(params);
    employeeData
      .then((data) => {
        setContractNumber(data[0].ContractNumber)
        setTeam(data[0].TeamID)
        setStartDate(dayjs(data[0]?.Start))
        setContractType(data[0].Limited === "False" ? "unbefristet" : "befristet")
        setEndDate(dayjs(data[0]?.End == "" ? new Date() : data[0]?.End))

        const times = data[0].Times;
        const updatedEditable = {
          monday: !!times.Monday,
          tuesday: !!times.Tuesday,
          wednesday: !!times.Wednesday,
          thursday: !!times.Thursday,
          friday: !!times.Friday,
          saturday: !!times.Saturday,
          sunday: !!times.Sunday,
        };
        setEditable(updatedEditable);

        const updatedStartTime = { ...startTime };
        const updatedEndTime = { ...endTime };

        if (times.Monday) {
          updatedStartTime.monday = new Date().setHours(...times.Monday.Start.split(':'));
          updatedEndTime.monday = new Date().setHours(...times.Monday.End.split(':'));
        }
        if (times.Tuesday) {
          updatedStartTime.tuesday = new Date().setHours(...times.Tuesday.Start.split(':'));
          updatedEndTime.tuesday = new Date().setHours(...times.Tuesday.End.split(':'));
        }
        if (times.Wednesday) {
          updatedStartTime.wednesday = new Date().setHours(...times.Wednesday.Start.split(':'));
          updatedEndTime.wednesday = new Date().setHours(...times.Wednesday.End.split(':'));
        }
        if (times.Thursday) {
          updatedStartTime.thursday = new Date().setHours(...times.Thursday.Start.split(':'));
          updatedEndTime.thursday = new Date().setHours(...times.Thursday.End.split(':'));
        }
        if (times.Friday) {
          updatedStartTime.friday = new Date().setHours(...times.Friday.Start.split(':'));
          updatedEndTime.friday = new Date().setHours(...times.Friday.End.split(':'));
        }
        if (times.Saturday) {
          updatedStartTime.saturday = new Date().setHours(...times.Saturday.Start.split(':'));
          updatedEndTime.saturday = new Date().setHours(...times.Saturday.End.split(':'));
        }
        if (times.Sunday) {
          updatedStartTime.sunday = new Date().setHours(...times.Sunday.Start.split(':'));
          updatedEndTime.sunday = new Date().setHours(...times.Sunday.End.split(':'));
        }

        setStartTime(updatedStartTime);
        setEndTime(updatedEndTime);

      })
      .catch((error) => {
        console.log(error)
      });
  }, [])

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (event) => {
    setTeam(event.target.value);
  };

  console.log(startDate)
  console.log(endDate)

  var data = {
    "ID": employeeID,
    "ContractNumber": contractNumber,
    "TeamID": "0",
    "Start": startDate?.toISOString()?.split('T')[0],
    "Limited": false,
    "End": contractType === "unbefristet" ? "" : endDate?.toISOString()?.split('T')[0],
    "times": {
      ...(editable.monday && {
        Monday: {
          start: extractTimeFromTimestamp(startTime.monday),
          end: extractTimeFromTimestamp(endTime.monday),
        },
      }),
      ...(editable.tuesday && {
        Tuesday: {
          start: extractTimeFromTimestamp(startTime.tuesday),
          end: extractTimeFromTimestamp(endTime.tuesday),
        },
      }),
      ...(editable.wednesday && {
        Wednesday: {
          start: extractTimeFromTimestamp(startTime.wednesday),
          end: extractTimeFromTimestamp(endTime.wednesday),
        },
      }),
      ...(editable.thursday && {
        Thursday: {
          start: extractTimeFromTimestamp(startTime.thursday),
          end: extractTimeFromTimestamp(endTime.thursday),
        },
      }),
      ...(editable.friday && {
        Friday: {
          start: extractTimeFromTimestamp(startTime.friday),
          end: extractTimeFromTimestamp(endTime.friday),
        },
      }),
      ...(editable.saturday && {
        Saturday: {
          start: extractTimeFromTimestamp(startTime.saturday),
          end: extractTimeFromTimestamp(endTime.saturday),
        },
      }),
      ...(editable.sunday && {
        Sunday: {
          start: extractTimeFromTimestamp(startTime.sunday),
          end: extractTimeFromTimestamp(endTime.sunday),
        },
      }),
    }
  }

  const sendData = () => {
    setConfirmationModalOpen(true);
  }

  useEffect(() => {
    if (disableFields) {
      setDisableFields(false);
      var response = PostContract(params, data);
      response
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [disableFields])

  var { ThemeToggle } = ToggleTheme();
  var textColor = ThemeToggle === "dark" ? "white" : "black";
  var radioLabelColor = ThemeToggle === "dark" ? "white" : "";

  return (
    <>
      <Box sx={{ mt: "4px", ml: "10px" }}>
        <Typography variant="h4" gutterBottom>
          {t("add-contract")}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr" },
            gap: 2,
          }}
        >
          <Box>
            <Box className="flex align-center items-center">
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  mr: "19px",
                  color: textColor,
                }}
              >
                {t("contract-number")}
              </InputLabel>
              <TextField
                size="small"
                id="standard-basic"
                value={contractNumber}
                onChange={(e) => { setContractNumber(e.target.value) }}
                autoComplete="off"
                sx={{ boxShadow: 1, width: "200px" }}
              />
            </Box>
            <Box sx={{ display: "flex", mt: "10px", color: "black" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: textColor,
                  mt: "8px",
                }}
              >
                {t("team")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={team}
                onChange={handleChange}
                sx={{
                  height: "40px",
                  width: "200px",
                  ml: "100px",
                  boxShadow: 1,
                }}
              >
                {/* Integrate Team Here */}
                <MenuItem value={"0"}>None</MenuItem>
              </Select>
            </Box>
            <Box sx={{ display: "flex", mt: "10px", alignItems: "center" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  mr: "29px",
                  color: textColor,
                }}
              >
                {t("start-contract")}
              </InputLabel>
              <CustomDatePicker Date={startDate} />
            </Box>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={contractType === "befristet" ? "befristet" : "unbefristet"}
              name="radio-buttons-group"
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: "10px",
              }}
              onChange={(e, val) => console.log(setContractType(val))}
            >
              <FormControlLabel
                value="befristet"
                control={
                  <Radio
                    style={{
                      color: radioLabelColor,
                    }}
                  />
                }
                label={t("limited")}
              />
              <FormControlLabel
                value="unbefristet"
                control={
                  <Radio
                    style={{
                      color: radioLabelColor,
                    }}
                  />
                }
                label={t("unlimited")}
              />
            </RadioGroup>
            {contractType === "befristet" && (
              <Box sx={{ display: "flex", mt: "10px", alignItems: "center" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    mr: "115px",
                    color: textColor,
                  }}
                >
                  {t("until")}
                </InputLabel>
                <CustomDatePicker Date={endDate} />
              </Box>
            )}
            <Divider sx={{ mt: "24px", mb: "10px", width: "350px" }} />
          </Box>
        </Box>

        <ContractDaysAndTime label={label} setEditable={setEditable} editable={editable} ThemeToggle={ThemeToggle} startTime={startTime} endTime={endTime} setStartTime={setStartTime} setValidateData={setValidateData} setEndTime={setEndTime} />

        <Box sx={{ display: "flex", mb: "100px", mt: "20px" }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '140px',
            height: '40px',
            backgroundColor: theme.palette.dullGray.main,
            padding: '8px 32px',
            borderRadius: '12px',
            '&:hover': {
              cursor: 'pointer',
            },
          }}>
            <Typography
              className={
                "flex font-bold " +
                (ThemeToggle === "dark" ? "text-browndark" : "")
              }>
              {t("cancel")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '140px',
              height: '40px',
              paddingY: 1,
              paddingX: 8,
              marginLeft: 2,
              borderRadius: '12px',
              backgroundColor: validateData ? theme.palette.green.main : theme.palette.narrowDull.main,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => { sendData(); }}
          >
            <Typography className="flex font-bold text-white">{t("save")}</Typography>
            <Box component="span" className="ml-2 ">
              <Tick />
            </Box>
          </Box>
        </Box>
      </Box >

      <Confirmation
        disableFields={disableFields}
        setDisableFields={setDisableFields}
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
      />
    </>
  );
}
