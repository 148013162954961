import dayjs from 'dayjs';
import React from 'react';
import ErrorBoundary from "../utils/ErrorBoundary";
import { Box, Checkbox, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

function CustomTimer({ day, label, startTime, endTime, editable, setEditable, setStartTime, setEndTime, checkWorkingHours, styleTimepicker, ThemeToggle }) {

    function extractTimeFromTimestamp(timestamp) {
        const tempDate = new Date(timestamp);
        var formattedData = `${tempDate.getFullYear() +
            "-" +
            (tempDate.getMonth() + 1) +
            "-" +
            tempDate.getDate() +
            " " +
            tempDate.getHours().toString().padStart(2, "0") +
            ":" +
            tempDate.getMinutes().toString().padStart(2, "0")
            }`;
        return formattedData;
    }

    return (
        <ErrorBoundary>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                    {...label}
                    checked={editable[day.name]}
                    onClick={() => setEditable({ ...editable, [day.name]: !editable[day.name] })}
                    style={{ color: ThemeToggle === "dark" ? "white" : "" }}
                />
                <Box sx={{ width: "20px", mr: "25px" }}>
                    <Typography>{day.label}</Typography>
                </Box>
                <Box sx={{ width: "107px", mr: "8px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={[""]}>
                            <DemoItem>
                                <TimePicker
                                    views={["hours", "minutes"]}
                                    readOnly={!editable[day.name]}
                                    value={dayjs(startTime[day.name])}
                                    ampm={false}
                                    onChange={(e) => {
                                        const temp1 = extractTimeFromTimestamp(e);
                                        const temp2 = extractTimeFromTimestamp(endTime[day.name]);
                                        setStartTime({ ...startTime, [day.name]: temp1 });
                                        checkWorkingHours(temp1, temp2);
                                    }}
                                    sx={styleTimepicker(!editable[day.name])}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </Box>
                <HorizontalRuleIcon sx={{ width: "10px" }} />
                <Box sx={{ width: "107px", ml: "8px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={[""]}>
                            <DemoItem>
                                <TimePicker
                                    views={["hours", "minutes"]}
                                    readOnly={!editable[day.name]}
                                    value={dayjs(endTime[day.name])}
                                    ampm={false}
                                    onChange={(e) => {
                                        const temp = extractTimeFromTimestamp(e);
                                        setEndTime({ ...endTime, [day.name]: temp });
                                        checkWorkingHours(startTime[day.name], temp);
                                    }}
                                    sx={styleTimepicker(!editable[day.name])}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </Box>
            </Box >
        </ErrorBoundary>

    )
}

export default CustomTimer;

