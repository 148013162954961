import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import BreakIcon from '@mui/icons-material/Coffee';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import MuiAccordion from '@mui/material/Accordion';
import TableContainer from '@mui/material/TableContainer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useTheme } from '../context/UseThemeProvider';
import { calculateDuration } from '../utils/CalculateDuration';
import { extractTimeFromTimestamp } from '../utils/ExtractTime';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(0deg)',
        transition: 'transform 0.3s',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions(props) {
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [dateLists, setDateLists] = React.useState({});
    const { currentTheme: theme } = useTheme();
    const { editable, filteredItem, detailTime } = props;

    function getScheduleForDate(date) {
        const formattedDate = date.toISOString().split("T")[0];

        if (!dateLists[formattedDate]) {
            const schedule = [];

            if (detailTime?.hasOwnProperty(formattedDate)) {
                const entries = detailTime[formattedDate];
                for (const entry of entries) {
                    const startTime = new Date(entry.start.date);
                    const endTime = new Date(startTime.getTime() + entry.duration * 1000);
                    schedule.push({
                        type: entry.type,
                        source_id: entry.source_id,
                        start: entry.start.date,
                        end: endTime.toISOString(),
                        isStart: entry.isStart,
                        isEnd: entry.isEnd,
                    });
                }
            }

            setDateLists(prevState => ({
                ...prevState,
                [formattedDate]: schedule
            }));
        }
    }

    const handleIconClick = (event, item) => {
        event.stopPropagation();
        setSelectedItems((prevSelected) => {
            if (prevSelected.find((selected) => selected.date === item.date)) {
                return prevSelected.filter((selected) => selected.date !== item.date);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const isSelected = (item) => {
        return selectedItems.find((selected) => selected.date === item.date) !== undefined;
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: "190px" }}>Type</TableCell>
                        <TableCell sx={{ width: editable ? "300px" : "340px" }}>Start Time</TableCell>
                        <TableCell sx={{ width: "320px" }}>End Time</TableCell>
                        <TableCell sx={{ minWidth: "20px" }}>Time</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>{editable && "Action"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredItem && filteredItem.length > 0 && filteredItem.map((item, index) => (
                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell colSpan={8} sx={{ p: 0 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            aria-controls={`panel${index}-content`}
                                            id={`panel${index}-header`}
                                            sx={{ backgroundColor: theme.palette.secondary.main, color: "white" }}
                                            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                            onClick={() => { getScheduleForDate(new Date(item.date)) }}
                                        >
                                            <Table size="small" aria-label={`details for ${item.date}`}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{ color: "white", width: "240px" }} colSpan={2}>{item.date}</TableCell>
                                                        <TableCell sx={{ color: "white" }}>{item.time}</TableCell>
                                                        <TableCell sx={{ color: "white" }}>offen</TableCell>
                                                        <TableCell onClick={(event) => handleIconClick(event, item)}>
                                                            {editable && (
                                                                <CheckCircleOutlineIcon
                                                                    sx={{ color: isSelected(item) ? theme.palette.green.main : 'gray' }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table size="small" aria-label={`details for ${item.date}`}>
                                                <TableBody>
                                                    {dateLists[item.date]?.map((session, sessionIndex) => (
                                                        <TableRow key={sessionIndex}>
                                                            <TableCell sx={{ width: "200px" }}>{session.type === 'break' ? <BreakIcon /> : <WorkOutlineIcon />} </TableCell>
                                                            <TableCell sx={{ width: editable ? "280px" : "330px" }}>{extractTimeFromTimestamp(session.start)}</TableCell>
                                                            <TableCell sx={{ width: "310px" }}>{extractTimeFromTimestamp(session.end)}</TableCell>
                                                            <TableCell>{calculateDuration(session.start, session.end)}</TableCell>
                                                            <TableCell>offen</TableCell>
                                                            <TableCell onClick={(event) => handleIconClick(event, item)}>
                                                                {editable && (
                                                                    <CheckCircleOutlineIcon
                                                                        sx={{ color: isSelected(item) ? theme.palette.green.main : 'gray' }}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
