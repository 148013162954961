import { api } from "../utils/axiosInstance";
import { getAuthHeaders } from "../utils/axiosInstance";

const FetchTimes = async (param) => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}${param}`,
        getAuthHeaders()
    );
    return response.data;
};

export {
    FetchTimes
};