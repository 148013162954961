import React from 'react'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function CustomDatePicker({ Date = null }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={[""]}>
        <DemoItem>
          <DatePicker defaultValue={Date} format="DD/MM/YYYY" sx={{ width: "200px" }} />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  )
}

export default CustomDatePicker