import React from "react";
import { useUser } from "../context/UserProvider.js";
import ErrorBoundary from "../utils/ErrorBoundary";
import ProtectedRoute from "../utils/ProtectedRoute.js";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./Authentication/Components/SignIn.tsx";
import HRDashboard from "./HRDashboard/Components/HRDashboard.jsx";
import ForgetPassword from "./Authentication/Components/ForgetPassword.tsx";

function App() {
  const { user } = useUser();

  function dashboard() {
    if (user === null || user.role === null) {
      return <SignIn />;
    }
    return <HRDashboard />;
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path=""
          index
          element={
            <ProtectedRoute isAllowed={!!user} redirectPath="Anmelden">
              <ErrorBoundary>
                {dashboard()}
              </ErrorBoundary>
            </ProtectedRoute>
          }
        />
        <Route
          path="Anmelden"
          element={user ? <Navigate to="/" replace /> : <SignIn />}
        />
        <Route path="resetPassword" element={<ForgetPassword />} />

        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
