import { api } from "../utils/axiosInstance";
import { getAuthHeaders } from "../utils/axiosInstance";

const AddPerson = async (data) => {
    const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/users`,
        data,
        getAuthHeaders()
    );
    return response;
};


const GetAllPerson = async () => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/persons`,
        getAuthHeaders()
    );
    return response.data;
};

const DeletePerson = async (data) => {
    const response = await api.delete(
        `${process.env.REACT_APP_API_URL}/api/persons`,
        data,
        getAuthHeaders()
    );
    return response.data;
};

const CheckPersonUsername = async (data) => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/validate/username/${data}`,
        getAuthHeaders()
    );
    return response.data;
};

const CheckPersonEmail = async (data) => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/validate/email/${data}`,
        getAuthHeaders()
    );
    return response.data;
};

const CheckPersonEmployeeNumber = async (data) => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/validate/employee/${data}`,
        getAuthHeaders()
    );
    return response.data;
};

export {
    AddPerson,
    DeletePerson,
    GetAllPerson,
    CheckPersonEmail,
    CheckPersonUsername,
    CheckPersonEmployeeNumber
};