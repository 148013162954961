import dayjs from "dayjs";
import * as React from "react";
import { ToggleTheme } from "../../../../context/ToggleThemeProvider";
import { useTheme } from "../../../../context/UseThemeProvider";
import { editStyle } from "../../../Person/Style/PersonModelStyle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Button, Box, Modal, Typography } from "@mui/material";
import { Done, Edit, WorkOutline, Restore, CoffeeOutlined, CloseOutlined, ArrowRightAlt, DeleteForeverOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";


export default function CurrentDay(props) {
    const { modalOpen, modalDate, disableFields, setDisableFields, validUpdate, setConfirmationModalOpen, setModalOpen,
        dateList, UpdateFields, setUpdateItems, extractTimeFromTimestamp, checkCancel, setCheckCancel, deleteItems, setDeleteItems
    } = props;
    const [cancelDay, setCancelDay] = React.useState(false);
    var { ThemeToggle } = ToggleTheme();
    const { currentTheme: theme } = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={editStyle}
                    style={{
                        zIndex: 1,
                        minWidth: "100px",
                        maxHeight: "600px",
                        overflow: "scroll",
                        backgroundColor: (ThemeToggle === "dark" ? theme.palette.secondary.dark : "")
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                        <Typography variant="h5">
                            <Box
                                sx={{ fontWeight: 600, w: 100 }}
                                style={{
                                    color: (ThemeToggle === "dark" ? "white" : "narrowWhite")
                                }}
                            >
                                {modalDate}
                            </Box>
                        </Typography>
                        <Box>
                            {disableFields && (
                                <Button
                                    endIcon={<Edit />}
                                    variant="contained"
                                    style={{
                                        height: "35px",
                                        color: "white",
                                        backgroundColor: theme.palette.blue.main,
                                        textTransform: "capitalize",
                                    }}
                                    onClick={() => {
                                        setDisableFields(!disableFields);
                                    }}
                                >
                                    {t("edit")}
                                </Button>
                            )}
                            {!disableFields && (
                                <Button
                                    endIcon={<Done />}
                                    variant="contained"
                                    style={{
                                        height: "35px",
                                        color: "white",
                                        textTransform: "capitalize",
                                        backgroundColor: validUpdate ? "green" : theme.palette.brownDark.main
                                    }}
                                    onClick={() => {
                                        if (validUpdate) {
                                            setConfirmationModalOpen(true);
                                        }
                                    }}
                                >
                                    {t("complete")}
                                </Button>
                            )}
                            <CloseOutlined
                                sx={{ ml: 2, color: ThemeToggle === "dark" ? "white" : "" }}
                                className="cursor-pointer"
                                onClick={() => {
                                    setModalOpen(false);
                                    setDeleteItems([]);
                                    setUpdateItems([]);
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        autoComplete="off"
                        noValidate
                        sx={{ backgroundColor: "darkwhite" }}
                    >
                        {dateList?.map((data, index) => {
                            return (
                                <Box sx={{ display: "flex", position: "relative" }} key={index}>
                                    <Box sx={{ paddingTop: 2, mr: 2, ml: 1, mb: 2 }}>
                                        {data?.type === "work" ? (
                                            <WorkOutline style={{ width: 30, height: 40, color: ThemeToggle === "dark" ? "white" : "" }} />
                                        ) : (
                                            <CoffeeOutlined style={{ width: 30, height: 40, color: ThemeToggle === "dark" ? "white" : "" }} />
                                        )}
                                    </Box>
                                    <div className="flex justify-start items-center text-lightdark">
                                        <div className="w-[110px]">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={[""]}>
                                                    <DemoItem>
                                                        <TimePicker
                                                            views={["hours", "minutes"]}
                                                            value={dayjs(data.start)}
                                                            readOnly={disableFields || checkCancel[index]}
                                                            ampm={false}
                                                            onChange={(e) => {
                                                                data.start = extractTimeFromTimestamp(e);
                                                                UpdateFields(data);
                                                            }}
                                                            sx={{
                                                                borderColor: "black",
                                                                borderRadius: "10px",
                                                                backgroundColor: ThemeToggle === "white" && disableFields ? theme.palette.dullGray.main : ThemeToggle === "white" && !disableFields ? "" : ThemeToggle === "dark" && disableFields ? "" : "white",
                                                                input: {
                                                                    color: (ThemeToggle === "white" && disableFields ? theme.palette.secondary.main : ThemeToggle === "white" && !disableFields ? "black" : ThemeToggle === "dark" && disableFields ? "white" : "black"),
                                                                },
                                                                svg: { color: disableFields ? theme.palette.brownDark.main : "black" },
                                                            }}
                                                        />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <Box sx={{ paddingTop: 2, paddingLeft: 2 }}>
                                        <ArrowRightAlt style={{ width: 30, height: 40, color: ThemeToggle === "dark" ? "white" : "" }} />
                                    </Box>

                                    <div className="flex justify-start items-center text-lightdark">
                                        <div className="w-[110px] ml-2">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={[""]}>
                                                    <DemoItem>
                                                        <TimePicker
                                                            views={["hours", "minutes"]}
                                                            value={dayjs(data?.end)}
                                                            ampm={false}
                                                            readOnly={disableFields || checkCancel[index]}
                                                            onChange={(e) => {
                                                                data.end = extractTimeFromTimestamp(e);
                                                                UpdateFields(data);
                                                            }}
                                                            sx={{
                                                                borderColor: "black",
                                                                borderRadius: "10px",
                                                                backgroundColor: ThemeToggle === "white" && disableFields ? theme.palette.dullGray.main : ThemeToggle === "white" && !disableFields ? "" : ThemeToggle === "dark" && disableFields ? "" : "white",
                                                                input: {
                                                                    color: (ThemeToggle === "white" && disableFields ? theme.palette.secondary.main : ThemeToggle === "white" && !disableFields ? "black" : ThemeToggle === "dark" && disableFields ? "white" : "black"),
                                                                },
                                                                svg: { color: disableFields ? theme.palette.brownDark.main : "black" },
                                                            }}
                                                        />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <Box
                                        sx={{ paddingTop: 2, paddingLeft: 1 }}
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (!disableFields) {
                                                if (data.type === "work") {
                                                    if (checkCancel[index]) {
                                                        const resetCheckCancel = checkCancel.map((e) => e ? !e : e);
                                                        setDeleteItems([])
                                                        setCheckCancel(resetCheckCancel);
                                                    }
                                                    else {
                                                        const resetCheckCancel = checkCancel.map((e) => e ? e : !e);
                                                        setDeleteItems([data.source_id])
                                                        setCheckCancel(resetCheckCancel);
                                                    }
                                                    setCancelDay(pre => !pre);
                                                }
                                                else if (!cancelDay) {
                                                    const updatedCheckCancel = [...checkCancel];
                                                    updatedCheckCancel[index] = !updatedCheckCancel[index];
                                                    setCheckCancel(updatedCheckCancel);
                                                    const isPresent = deleteItems.includes(data.source_id);
                                                    if (isPresent) {
                                                        setDeleteItems(prevItems => prevItems.filter(id => id !== data.source_id));
                                                    } else {
                                                        setDeleteItems(prevItems => [...prevItems, data.source_id]);
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        {!checkCancel[index] && (
                                            <DeleteForeverOutlined
                                                style={{
                                                    width: 30,
                                                    height: 40,
                                                    color: ThemeToggle === "white" && disableFields ? theme.palette.dull.main : ThemeToggle === "dark" && disableFields ? theme.palette.secondary.main : theme.palette.accent.main,
                                                }}
                                            />
                                        )}
                                        {checkCancel[index] && (
                                            <Restore
                                                style={{
                                                    width: 30,
                                                    height: 40,
                                                    color: disableFields ? theme.palette.secondary.main : theme.palette.accent.main,
                                                }}
                                            />
                                        )}
                                    </Box>
                                    {checkCancel[index] && (
                                        <div
                                            style={{
                                                display: "block",
                                                position: "absolute",
                                                height: "2%",
                                                width: "92%",
                                                bottom: "50%",
                                                background: "black",
                                            }}
                                        ></div>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Modal >

        </>
    );
}

