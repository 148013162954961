import React, { useEffect, useState } from "react";
import BoxStyle from "../../../Person/Style/PersonStyle.js";
import { EyeLogo } from "../../../../Images/CommonLogo.jsx";
import MonthSelectorComponent from "../../../../Component/MonthSelectorComponent.jsx";
import Confirmation from "./Confirmation.jsx";
import CurrentDay from "./CurrentDay.jsx";
import Detail from "./Detail.jsx";
import CsvDownloader from "../../../../Component/CsvDownloader.jsx";
import { ToggleTheme } from "../../../../context/ToggleThemeProvider.js";
import DownloadIcon from '@mui/icons-material/Download';
import {
  DeleteWorkOrBreakTime,
  UpdateWorkOrBreakTime,
  FetchTimes,
} from "../../../../Api/AddTimerApi.js";
import Style from "../../../UserDetails2/Style/Style.js";
import { useTranslation } from "react-i18next";

function UserTable(props) {
  const { t } = useTranslation();
  const {
    filteredItems, setFilteredItems, downloadItems, setDownloadItems, currentMonth, setCurrentMonth, currentYear, setCurrentYear,
    open, setOpen, months, currentDate, setCurrentDate, minDate, setMinDate, detailTime, setDetailTime,
    setDetailModalOpen, detailModalOpen, convertSecondsToTimeFormat
  } = props
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [disableFields, setDisableFields] = useState(true);
  const [modalDate, setModalDate] = useState("");
  const [deleteItems, setDeleteItems] = React.useState([]);
  const [updateItems, setUpdateItems] = React.useState([]);
  const [validUpdate, setValidUpdate] = useState(true);
  var { ThemeToggle } = ToggleTheme();
  const [checkCancel, setCheckCancel] = useState(
    Array.from({ length: 10 }, () => false)
  );

  const MonthSelectorProps = {
    currentMonth, setCurrentMonth, currentYear, setCurrentYear, open,
    setOpen, months, currentDate, setCurrentDate, minDate, setMinDate
  }
  const CurrentDayProps = {
    modalOpen, modalDate, disableFields, setDisableFields, validUpdate, setConfirmationModalOpen, setModalOpen,
    dateList, UpdateFields, setUpdateItems, checkCancel, setCheckCancel, deleteItems, extractTimeFromTimestamp, setDeleteItems
  }
  const DetailsProps = {
    setDetailModalOpen, detailModalOpen
  }

  const ConfirmationProps = { confirmationModalOpen, setDisableFields, setConfirmationModalOpen, disableFields }

  function getScheduleForDate(date) {
    const formattedDate = date.toISOString().split("T")[0];
    const schedule = [];

    if (detailTime?.hasOwnProperty(formattedDate)) {
      const entries = detailTime[formattedDate];
      for (const entry of entries) {
        const startTime = new Date(entry.start.date);
        const endTime = new Date(startTime.getTime() + entry.duration * 1000);
        schedule.push({
          type: entry.type,
          source_id: entry.source_id,
          start: entry.start.date,
          end: endTime.toISOString(),
          isStart: entry.isStart,
          isEnd: entry.isEnd,
        });
      }
    }
    setDateList(schedule);
  }


  function extractTimeFromTimestamp(timestamp) {
    const tempDate = new Date(timestamp);
    var formattedData = `${tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate() +
      " " +
      tempDate.getHours().toString().padStart(2, "0") +
      ":" +
      tempDate.getMinutes().toString().padStart(2, "0")
      }`;
    return formattedData;
  }


  function UpdateFields(data) {
    data.end = extractTimeFromTimestamp(data.end);
    if (data.start < data.end) {
      setValidUpdate(true);
      setUpdateItems((prev) => {
        const existingIndex = prev.findIndex(
          (item) => item.id === data.source_id
        );
        if (existingIndex !== -1) {
          const updatedItems = [...prev];
          updatedItems[existingIndex] = {
            ...updatedItems[existingIndex],
            "start": data.start,
            "end": data.end,
          };
          return updatedItems;
        } else {
          return [
            ...prev,
            {
              "id": data.source_id,
              "start": data.start,
              "end": data.end,
            },
          ];
        }
      });
    } else {
      setValidUpdate(false);
    }
  }

  const deleteItemsAsync = () => {
    deleteItems.map((id) => {
      var deleteRecord = DeleteWorkOrBreakTime({ 'id': id })
      deleteRecord.then((res) => {
        setCheckCancel(Array.from({ length: 10 }, () => false));
        setModalOpen(false);
      }).catch((error) => {
      })
    })
    setDeleteItems([]);
  };


  const updateItemAsync = () => {
    updateItems.map((data) => {
      var deleteRecord = UpdateWorkOrBreakTime(data)
      deleteRecord.then((data) => {
        setModalOpen(false)
      }).catch((error) => {
        alert(error);
      })
    })
    setUpdateItems([]);
  }

  const getItemAsync = () => {

    //Parameters to send in get request for whole month
    var params = `/api/me/times?from=${currentYear}-${currentMonth + 1
      }-01&to=${currentYear}-${currentMonth + 2}-01&mode=sum`;

    var userRecord = FetchTimes(params);
    userRecord
      .then((data) => {
        if (data) {
          setFilteredItems(
            Object.keys(data)?.map((key) => {
              const entry = data[key];
              return {
                id: key,
                date: key,
                time: convertSecondsToTimeFormat(entry.total),
              };
            })
          );
          setDownloadItems(
            Object.keys(data)?.map((key) => {
              const entry = data[key];
              return {
                Datum: key,
                Dauer: convertSecondsToTimeFormat(entry.total),
              };
            })
          );
        }
      })
      .catch((error) => {
      });


    //Parameters to send in get request for whole Day
    var params2 = `/api/me/times?from=${currentYear}-${currentMonth + 1
      }-01&to=${currentYear}-${currentMonth + 2}-01&mode=perday`;

    var userRecord1 = FetchTimes(params2);
    userRecord1
      .then((data) => {
        if (data) {
          setDetailTime(data);
        }
      })
      .catch((error) => {
        //Error to show here
      });
  }

  useEffect(() => {
    if (modalOpen == false) {
      getItemAsync()
    }
  }, [modalOpen])


  useEffect(() => {
    if (deleteItems.length !== 0) {
      deleteItemsAsync();
    }
    if (updateItems.length !== 0) {
      updateItemAsync();
    }
  }, [disableFields]);

  return (
    <>
      <div className="h-fit">
        <button style={{ color: ThemeToggle === "dark" ? "white" : "black", padding: 5, fontWeight: 900 }} onClick={() => {
          CsvDownloader(downloadItems, (months[currentMonth]) + " " + currentYear);
        }}> {t("download")} <DownloadIcon />
        </button>

        <div className={"relative bg-dullBlack lg:hidden flex justify-center w-[100%]"}>
          <MonthSelectorComponent
            {...MonthSelectorProps}
          />
        </div>
        <table
          className={"table-auto w-full block overflow-y-scroll md:table" + (ThemeToggle === "dark" ? " bg-gray" : "")}
          style={{ height: "calc(100%-250px)]" }}
        >
          <thead>
            <tr>
              <th className={BoxStyle.th}>{t("date")}</th>
              <th className={BoxStyle.th}>{t("total-time")}</th>
              <th className={BoxStyle.th}>{t("details")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems?.map((item, index) => (
              <tr
                key={item.id}
                className={index % 2 === 0 ? " bg-narrow " : " bg-textBox"}
              >
                <td className={BoxStyle.td + " md:pl-8 w-[100%] md:w-auto text-nowrap	"}>
                  {item.date}
                </td>
                <td className={BoxStyle.td + " md:pl-16 "}>
                  {item.time}
                </td>
                <td
                  className={BoxStyle.td + " md:pl-[80px] cursor-pointer"}
                  onClick={() => {
                    setDisableFields(true);
                    setModalOpen(true);
                    setModalDate(item.date);
                    setCheckCancel(Array.from({ length: 10 }, () => false))
                    getScheduleForDate(new Date(item.date));
                  }}
                >
                  <EyeLogo />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {
          filteredItems?.length === 0 && (
            <div className={Style.noRecord}>
              {t("no-recordFound")}
            </div>
          )
        }
      </div >
      <CurrentDay
        {...CurrentDayProps}
      />
      <Confirmation
        {...ConfirmationProps}
      />
      <Detail {...DetailsProps} />


    </>
  );
}

export default UserTable;