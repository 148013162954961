import React from "react";
import MonthSelectorComponent from "../../../Component/MonthSelectorComponent";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Menu, } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info';
import BreadCrumb from "../../../Component/BreadCrumb";

export function Header({
    drawerWidth,
    handleDrawerToggle,
    currentComponent,
    setCurrentComponent,
    currentMonth,
    setCurrentMonth,
    currentYear,
    setCurrentYear,
    open,
    setOpen,
    months,
    currentDate,
    setCurrentDate,
    minDate,
    setMinDate,
    detailModalOpen,
    setDetailModalOpen,
    userVertragName,
}) {
    const MonthSelectorProps = {
        currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, minDate, setMinDate
    }
    return (
        <AppBar position="fixed" elevation={0} sx={{
            width: {
                sm: `calc(100% - ${drawerWidth}px)`
            },
            height: {
                sm: "63px"
            },
            ml: {
                sm: `${drawerWidth}px`
            },
            backgroundColor: "secondary.dark",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start"
        }}>
            <Toolbar>
                <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{
                    mr: 2,
                    display: {
                        sm: "none"
                    }
                }}>
                    <Menu />
                </IconButton>
                <Typography variant="h5" sx={{
                    color: "white",
                    display: "flex",
                    ml: 0,
                    mt: 1,
                    width: "max-content"
                }}>
                    <BreadCrumb currentComponent={currentComponent} setCurrentComponent={setCurrentComponent} userVertragName={userVertragName} />
                    {currentComponent === 3 && <InfoIcon className="cursor-pointer ml-2" onClick={() => { setDetailModalOpen(true) }} />}
                    {currentComponent === 2 && <h3>{localStorage.getItem("awo-employee")}</h3>}
                </Typography>
            </Toolbar>
            {currentComponent === 2 && <div className="ml-[33%] hidden lg:block pt-4">
                <div className="bg-dullbrown lg:block flex justify-center w-[100%]">
                    <MonthSelectorComponent {...MonthSelectorProps} />
                </div>
            </div>}
            {currentComponent === 3 && <div className="ml-[33%] hidden lg:block pt-4">
                <div className="bg-dullbrown lg:block flex justify-center w-[100%]">
                    <MonthSelectorComponent {...MonthSelectorProps} />
                </div>
            </div>}
            {currentComponent === 4 && <div className="ml-[5%] md:ml-[33%] block pt-4">
                <div className="bg-dullbrown flex justify-center w-[100%]">
                    <MonthSelectorComponent {...MonthSelectorProps} />
                </div>
            </div>}
        </AppBar>
    )
}
