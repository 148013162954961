import { api } from "../utils/axiosInstance";
import { getAuthHeaders } from "../utils/axiosInstance";

const FetchContract = async (params) => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}${params}`,
        getAuthHeaders()
    );
    return response.data;
};


const PostContract = async (params, data) => {
    const response = await api.post(
        `${process.env.REACT_APP_API_URL}${params}`,
        data,
        getAuthHeaders()
    );
    return response;
};


export {
    FetchContract,
    PostContract
};