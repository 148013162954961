import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Calender from "../Images/Calender";
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from "../utils/ErrorBoundary";

export default function ConditionalDatePicker({
  date,
  setDate,
  restrictStart,
  restrictEnd,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = React.useRef();

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setDate(e);
  };
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsOpen(false); // Close DatePicker if clicked outside of it
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <ErrorBoundary>
        <div className="mt-2 ml-2" onClick={() => handleClick()}>
          <Calender />
        </div>
        <div className="absolute" style={{ zIndex: 1000000 }} ref={datePickerRef}>
          {isOpen && (
            <DatePicker
              selected={date}
              setBlur={true}
              onChange={handleChange}
              inline
              minDate={new Date(restrictStart)} // to set the initial date
              maxDate={new Date(restrictEnd)}
            />
          )}
        </div>
      </ErrorBoundary>
    </>
  );
}
